<template>
  <div class="flex-row d-flex align-center">
    <v-checkbox
      v-if="hasSelectFeature"
      @change="toggleSelected"
      v-model="selected"
      :disabled="disabledSelect"
    />
    <clientItem :data="data" :list-key="listKey"/>
  </div>
  
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import listRow from '@/components/list/mixins/listRow';

export default {
  mixins: [listItem, listRow],
  data: () => {
    return {
      useData: true,
    }
  },
  components: {
    clientItem: () => import("@/modules/provider/components/clientItem/clientItem.vue"),
  },
};
</script>